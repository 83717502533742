'use client';

import React from 'react';
import { MediaKit } from '@widgets/mediakit';
import { defaultSlug } from '@shared/constants';
import { selectShouldFetch, useSelector } from '@shared/redux';

interface PageProps {
  params: Params;
  searchParams: SearchParams;
}

interface Params {
  username: string;
}

interface SearchParams {}

function PrivateMediaKit({ params: { username } }: PageProps) {
  const shouldFetch = useSelector(selectShouldFetch);

  return (
    <MediaKit
      slug={defaultSlug}
      username={username}
      showSkeleton={!!shouldFetch}
    />
  );
}

export default PrivateMediaKit;
